import React from "react";
import {createStyles, Paper, Tooltip, Typography, WithStyles, withStyles} from "@material-ui/core";
import {Fade} from "react-reveal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {GitHub, Link as MatLink} from "@material-ui/icons";

const pageProjectStyles = (theme: any) => createStyles({
    pageWrapper: {
        [theme.breakpoints.down('sm')]: {
            margin: '40px 10px 0 10px'
        },
        [theme.breakpoints.up('md')]: {
            margin: 40
        },
        [theme.breakpoints.up('lg')]: {
            margin: 40
        },
    },
    pageContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 100
    },
    titleContainer: {
        display: 'flex',
        justifyContent: "space-between"
    },
    item: {
        // height: '80vh',
        // marginBottom: 50
    },
    flexContainer: {
        display: 'flex'
    },
    row: {
        flexDirection: 'row',
    },
    paperWrapper: {
        height: '100%',
        padding: 30,
    },
    projectContentWrapper: {
        padding: 30
    },
    iframeCss: {
        display: 'inline-flex',
        margin: '20px 0 20px 0',
        [theme.breakpoints.down('sm')]: {
            width: 250,
            height: 150,
        },
        [theme.breakpoints.up('md')]: {
            width: 500,
            height: 300,
        },
        [theme.breakpoints.up('lg')]: {
            width: 700,
            height: 400,
        },

        justifyContent: 'center',
    },
    carouselWrapper: {
        display: "block",
        width: '100%'
    },
    carouselImage: {
        [theme.breakpoints.down('sm')]: {
            width: 250,
            height: 150,
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '30vh',
            maxWidth: '30vw',
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: '30vh',
            maxWidth: '30vw',
        },
        borderRadius: 10,
        padding: 20
    },
    projectDesc: {
        display: "flex",
        textAlign: 'initial'
    },
    linkList: {
        display: 'inline-flex',
        gap: 20
    },
    link: {
        color: 'inherit'
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};

const projects = [
    {
        projectTitle: "COMPUTER SCIENCE CAPSTONE - VIRTUAL REALITY DEVELOPMENT TEAM",
        projectDesc: "My team and I developed a training tutorial, called Launch Into VR, for high school students to get acquainted in virtual reality (VR). We also worked on an intuitive architecture so future information technology students can use our custom scripts and classes to add upon what we had accomplished.",
        projectImage: null,
        projectUrls: [
            {url: "https://launchintovr.com/", type: 'url', toolTip: 'Link to project'},
        ] ,
        embed: "https://www.youtube.com/embed/fnd29woNSPI"
    },
    {
        projectTitle: "DESIGN IN SOFTWARE ENGINEERING - PYTHON TEST TAKING PLATFORM",
        projectDesc: "My team and I developed an online test taking platform where teachers could login, make questions that get stored into a database, make a test from existing questions, and evaluate students tests after it was auto-graded. Then students could login and take tests made by the teacher. My contributions to the project was to create the middleware to bring the front and backend together. In doing so, I created the automated grading process that would look to see if the student used the right function name, if it compiled, and the desired output of the program given any amount of test cases.",
        projectImage: null,
        embed: null,
        images: [
            {image: '/assets/projects/CS490Login.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490MakeQ.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490MakeTest.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490Results.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490Review.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490Student.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490TeachLogin.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS490Test.png', alt: 'Make a test question'}
        ]
    },
    {
        projectTitle: "INTRO TO DATABASES - EDUTRACK",
        projectDesc: "Led a small team on making a university database application. The two students I worked with helped by designing the backend with some of my input. Then I created the JavaFX application to allow students to manage their courses or register for new ones.",
        projectImage: null,
        embed: null,
        images: [
            {image: '/assets/projects/CS431Login.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS431SearchCourses.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS431Grades.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS431Student.png', alt: 'Make a test question'},
            {image: '/assets/projects/CS431StudentManage.png', alt: 'Make a test question'},
        ]
    },

];

interface Props extends WithStyles<typeof pageProjectStyles>{}
interface IState {}
export class PageProjects extends React.Component<Props, IState> {

    videoEmbed(embed: any, css: any) {
        if (embed) {
            return (
                <div className={css}
                >
                    <iframe
                        width={'100%'}
                        height={'100%'}
                        src={embed}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                </div>

            )
        }
    }

    carousel(images: any, carouselCss: any, wrapperCss: any) {
        if (images) {
            return (
                <div className={wrapperCss}>
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        transitionDuration={500}
                        infinite={true}
                    >
                        {
                            images.map((o: any) => (
                                <div>
                                    <img src={o.image} alt={o.alt} className={carouselCss}/>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            )
        }
    }

    urlLinks(urls: [any], linkListCss: any, linkCss: any) {
        if (urls) {
            return (
                <div className={linkListCss}>
                    {
                        urls.map((url: any) => (
                            <div>
                                {url.type === 'url' ? <Tooltip title={url.toolTip}><a href={url.url} className={linkCss} target={'_blank'} rel={'noreferrer'}><MatLink/></a></Tooltip>: null}
                                {url.type === 'git' ? <Tooltip title={url.toolTip}><a href={url.url} className={linkCss} target={'_blank'} rel={'noreferrer'}><GitHub/></a></Tooltip>: null}
                            </div>
                        ))
                    }
                </div>
            )
        }
    }

    render() {
        const {classes} = this.props;
        console.log(classes)
        return (
            // <Fade bottom>
            <div className={classes.pageWrapper}>
                <title>Patrick Delong - Projects</title>
                <div className={classes.pageContentWrapper}>
                    {
                        projects.map((row: any) => (
                            <Fade right>
                                <div className={classes.item}>
                                    <Paper variant={'elevation'}
                                           elevation={3}
                                           className={classes.paperWrapper}
                                           >
                                        <div className={classes.titleContainer}>
                                            <div/>
                                            <div>
                                                <Typography variant={'h5'}>
                                                    {row.projectTitle}
                                                </Typography>
                                            </div>
                                            <div>
                                                {this.urlLinks(row.projectUrls, classes.linkList, classes.link)}
                                            </div>

                                        </div>

                                        {this.videoEmbed(row.embed, classes.iframeCss)}
                                        {this.carousel(row.images, classes.carouselImage, classes.carouselWrapper)}
                                        <div className={classes.flexContainer}>
                                            <div className={classes.projectDesc}>
                                                <Typography>
                                                    <b>Description:</b>
                                                    <br/>
                                                    {row.projectDesc}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </Fade>

                        ))
                    }
                </div>
            </div>
        );
    }
}
export default withStyles(pageProjectStyles, {})(PageProjects);
