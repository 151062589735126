import React, {ForwardedRef, PropsWithChildren} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, NavLink, Switch, useLocation, Redirect} from "react-router-dom";
import {
    AppBar,
    createTheme,
    createStyles,
    makeStyles,
    responsiveFontSizes,
    Theme,
    Toolbar,
    CssBaseline,
    Tabs, Tab, WithStyles, withStyles, Typography
} from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import Lottie from 'lottie-web';
import PageAbout from "./pages/about/page-about";
import axios from "axios";
import PageProjects from "./pages/projects/page-projects";
import {GitHub, LinkedIn} from "@material-ui/icons"
import PageContact from "./pages/contact/page-contact";

axios.interceptors.request.use(
    function (req) {
        req.headers = {
            Authorization: `Token ${process.env["REACT_APP_API_KEY"]}`,
        };
        return req;
    }
);

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// let firebaseConfig = {
//     apiKey: process.env["REACT_APP_FIREBASE_API_KEY"],
//     authDomain: "pdelong-me.firebaseapp.com",
//     projectId: "pdelong-me",
//     storageBucket: "pdelong-me.appspot.com",
//     messagingSenderId: process.env["REACT_APP_FIREBASE_SENDER_ID"],
//     appId: process.env["REACT_APP_FIREBASE_APP_ID"],
//     measurementId: process.env["REACT_APP_FIREBASE_MEASUREMENT_ID"]
// };

// firebase.initializeApp(firebaseConfig);

// export const analytics = firebase.analytics();

// analytics.setAnalyticsCollectionEnabled(true);



export let globalTheme = createTheme({
    palette: {
        primary: {
            main: "#6f6f70",
            contrastText: "#ffffff",
        },
        background: {
            default: '#e0dfdf'
        },
    },
    typography: {
        h3: {
            fontWeight: 700,
            fontStyle: 'normal'
        },
        h5: {
          fontWeight: 500,
          fontStyle: 'normal'
        },
        fontFamily: [
            'itc-avant-garde-gothic-pro',
            'sans-serif'
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 300,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    }
});

globalTheme = responsiveFontSizes(globalTheme);

const navBarStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    routeActiveStyle: {
        color: '#d61c1c',
        textDecoration: 'underline',
        textDecorationColor: '#ffffff',
    },
    routeStandardStyle: {
        color: '#070707'
    },
    animLogo: {
        height: 50,
        width: 50,
        borderRadius: '50%',
        // backgroundColor: '#ffffff'
    }

}));

const appStyles = (theme: any) => createStyles({
    app: {
        textAlign: 'center'
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        gap: 10,
        alignItems: 'center'
    },
    footerRow: {
        flexDirection: 'row',
        display: 'flex',
        gap: 10,
        alignItems: 'center'
    },
    footerColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        color: 'inherit'
    }
});

function NavBar(props: any) {

    const endpoints = [
        {
            key: 0,
            title: 'About',
            link: '/about'},
        {
            key: 1,
            title: 'Projects',
            link: '/projects'},
        // {
        //     key: 2,
        //     title: 'Contact',
        //     link: '/contact'
        // }
    ]
    const {pathname} = useLocation();
    const classes = navBarStyles();
    const selectedEndpoint = endpoints.find((elm: any) => pathname === elm.link);
    const [value, setValue] = React.useState(selectedEndpoint ? selectedEndpoint.key : 0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleOnClick = (props: any) => {
        setValue(props.children.key);
        props.navigate();
    };

    const logoOnClick = () => {
        // props.logoOnClick();
    };

    const TabLink = React.forwardRef((props:PropsWithChildren<any>, ref: ForwardedRef<any>) => (
      <Tab ref={ref}
           label={props.children.title}
           onClick={() => {handleOnClick(props)}}
      />
    ));
    return (
        <AppBar position="static" color='primary'>
            <Toolbar className={classes.toolbar}>
                <div className={classes.animLogo} id={'animLogo'} onClick={logoOnClick}/>
                <Tabs value={value}
                      onChange={handleChange}
                >
                    {endpoints.map((o: any) => (
                        <NavLink to={o.link}
                                 component={TabLink}
                                 children={o}
                                 key={o.key}
                        />
                    ))}
                </Tabs>
                <div/>
            </Toolbar>
        </AppBar>
    );
}

interface Props extends WithStyles<typeof appStyles>{}
export class App extends React.Component<Props, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            isShowingToaster: false,
            sideBarOpen: false
        };

    }

    componentDidMount() {
        const elm = document.getElementById('animLogo') as Element;
        const ref = Lottie.loadAnimation({
            container: elm, // the dom element that will contain the animation
            renderer: 'svg',
            autoplay: true,
            path: '/anim/new-logo/data.json',
            name: 'logo'
        });

        ref.addEventListener('loopComplete', function() {
            ref.goToAndStop(899, true);
        });
        this.setState({
            ... this.state,
            logoRef: ref
        });
    }

    playLogAnimation() {
        console.log(this.state);
        const {logoRef} = this.state;
        if (logoRef) {
            console.log("play anim");
            logoRef.addEventListener('loopComplete', function () {
                logoRef.goToAndStop(899, true);
            });
        }
    }

    showToaster(msg: string, type:string) {
        this.setState({isShowingToaster: true, msg, type: type ? type : 'informational'});
    }

    handleClosingToaster(event:any, reason:any) {

        if (reason === 'clickaway') {
            this.setState({isShowingToaster: false});
            return;
        }
        this.setState({isShowingToaster: false});
    }

    menuClicked(e:any) {
        this.setState(
            {
                sideBarOpen: true
            }
        )
    }

    handleClosingSideBar(reason: any) {
        this.setState({
            sideBarOpen: false
        });
        return;
    }

    render() {
        const {classes} = this.props;
        return (
            <ThemeProvider theme={globalTheme}>
                <div className={classes.app}>
                    <Router>
                        <NavBar handleClick={this.menuClicked.bind(this)}
                                presentToaster={this.showToaster.bind(this)}
                                logoOnClick={this.playLogAnimation.bind(this)}
                        />
                        {/*<SideBar open={this.state.sideBarOpen} handleClose={this.handleClosingSideBar.bind(this)}/>*/}
                        <CssBaseline/>
                        <Switch>
                            <Route exact path={'/'} key={'/'}>
                                <Redirect to={'/about'}/>
                            </Route>
                            <Route path={'/about'} key={'about'} component={PageAbout}/>
                            <Route path={'/projects'} key={'projects'} component={PageProjects}/>
                            {/*<Route path={'/contact'} key={'contact'} component={PageContact}/>*/}
                        </Switch>
                        <div className={classes.footerContainer}>
                            <div className={classes.footerRow}>
                                <div className={classes.footerColumn}>
                                    <a href={'https://github.com/pdelong21'} className={classes.link} target={'_blank'}><GitHub/></a>
                                </div>
                                <div className={classes.footerColumn}>
                                    <a href={'https://www.linkedin.com/in/pdelong21/'} className={classes.link} target={'_blank'}><LinkedIn/></a>
                                </div>
                            </div>
                            <div className={classes.footerRow}>
                                <Typography>
                                    Copyright 2021 Patrick Delong
                                </Typography>
                            </div>
                        </div>
                    </Router>
                </div>
            </ThemeProvider>
        );
    }
}

export default withStyles(appStyles, {})(App);
