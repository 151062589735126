import React from 'react';
import {Code, Crop, Book} from "@material-ui/icons";
import {Build} from "@material-ui/icons"
import {
    createStyles,
    Divider, List, ListItem, ListItemIcon, ListItemText,
    Paper,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {Fade} from 'react-reveal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const pageAboutStyles = (theme: any) =>  createStyles({
    wrapper: {
    },
    titleHeader: {
        whiteSpace:'nowrap'
    },
    contentWrapper: {
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            minHeight: '100vh',
            margin: '40px 10px 0 10px'
        },
        [theme.breakpoints.up('md')]: {
            padding: 20,
            minHeight: '100vh',
            margin: 40
        },
        [theme.breakpoints.up('lg')]: {
            padding: 20,
            minHeight: '100vh',
            margin: 40
        },
    },
    profileHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20
    },
    profileImage: {
        [theme.breakpoints.down('sm')]: {
            width: 75,
            height: 75,
        },
        [theme.breakpoints.up('md')]: {
            width: 125,
            height: 125,
        },
        [theme.breakpoints.up('lg')]: {
            width: 125,
            height: 125,
        },
        borderRadius: '50%'
    },
    col: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            minWidth: '200px',
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10vw'
    },
    sectionContent: {
        padding: '0 20px 0 20px',
        textAlign: 'left'
    },
    titleAlignLeft: {
        textAlign: 'left',
        marginBottom: 20
    },
    boldFont: {
       fontWeight: 'bold'
    },
    divider: {
        marginTop: 20,
        marginBottom: 20
    },
    wholePage: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '30vh',
        marginBottom: 20
    },
    educationInsert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    jobInsert: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    carouselImage: {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '300px',
        borderRadius: 10
    },
    carouselItemContainer: {
        padding: 20,
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    jobRow: {
        marginBottom: 10
    },
    wrapList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    itemWrapper: {
        minWidth: '15vw',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    jobCol: {
        flexDirection: 'column',
        alignSelf: 'center'
    }
});

const images = [
    {image: '/assets/about/about_couple.jpg', alt: 'Couple'},
    {image: '/assets/about/bike.jpg', alt: 'Person on motorcycle'},
    {image: '/assets/about/about_tm.jpg', alt: 'Competition'},
    {image: '/assets/about/about_col1.jpg', alt: 'Snowboarder'},
    {image: '/assets/about/about_col.jpg', alt: 'Scenic'},
    {image: '/assets/about/about_build_0.jpg', alt: 'Table'},
    {image: '/assets/about/table.jpg', alt: 'Table'},
];

const programmingLanguages = [
    {lang: 'Java'},
    {lang: 'Typescript'},
    {lang: 'Javascript'},
    {lang: 'Swift'},
    {lang: 'Python'},
];


const tools = [
    {tool: 'Git'},
    {tool: 'Gitlab'},
    {tool: 'Redhat Openshift'},
    {tool: 'Docker'},
    {tool: 'Kubernetes'},
    {tool: 'XCode'},
    {tool: "Jetbrains IDE's"},
    {tool: 'Jenkins'},
    {tool: 'Redis'},
    {tool: 'Jira'},
    {tool: 'Adobe XD'},
    {tool: 'Adobe Illustrator'},
    {tool: 'Adobe After Effects'},
];

const frameworks = [
    {framework: 'Angular'},
    {framework: 'Spring'},
    {framework: 'Django'},
    {framework: 'Core Bluetooth'},
    {framework: 'Core Animation'},
    {framework: 'UIKit'}
];

const libraries = [
    {library: 'React'},
    {library: 'Alamofire'},
    {library: 'Snapkit'},
    {library: 'Lottie'},
    {library: 'Axios'},
    {library: 'Material'}

]

const experiences = [
    {
        title: 'Senior Software Engineer',
        company: 'Kohl\'s - Remote',
        points: [
            // { detail: 'Backend API development using Java 17 with Spring boot frameworks' },
            // { detail: 'Connecting backend services to SQL databases (Postgres and IBM DB2)'},
            // { detail: 'Frontend web development building internal tooling using Angular as well as React' },
            // { detail: 'Implementing authentication mechanisms and cryptography for point to point encryption of data'},
            // { detail: 'Building CI/CD pipelines using Gitlab, Openshift, and Kubernetes' },
        ],
        duration: 'September 2023 - Present'
    },
    {
        title: 'Software Engineer',
        company: 'Kohl\'s - Remote',
        duration: 'August 2022 - September 2023',
        points: []
    },
    {
        title: 'Lead Full Stack Engineer',
        company: 'KIS Solutions, LLC - Remote',
        points: [
            { detail: 'Guide scrum meetings, manage project deliverables, and mentoring junior engineers on a small team size' },
            { detail: 'Conducting peer code reviews across frontend and backend projects as well as paired coding sessions' },
            { detail: 'Coordinating data setup and database optimization' },
            { detail: 'Backend API development in Java Spring and MySQL' },
            { detail: 'Frontend development in Angular, React, and native iOS' },
            { detail: 'UI/UX creating and modifying mockups in Adobe XD'},
            { detail: 'IoT development using Linux and edge devices'},
            { detail: 'Participated in on-call rotations for critical system support'},
        ],
        duration: 'July 2019 - July 2022'
    }
];

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 30

    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 30,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30

    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30

    }
};

interface Props extends WithStyles<typeof pageAboutStyles>{}
interface IState {}
export class PageAbout extends React.Component<Props, IState> {

    render() {
        const {classes} = this.props;

        return (
          <div className={classes.wrapper}>
              <title>Patrick Delong - About</title>
              <Fade bottom>
                  <Paper className={classes.contentWrapper}
                         variant={'elevation'}
                         elevation={3}
                  >
                      <div className={classes.profileHeaderContainer}>
                          <div className={classes.col}>
                              <img src={'/assets/about/profile.jpg'} alt={'Profile Photo'} className={classes.profileImage}/>
                          </div>
                          <div className={classes.col}>
                              <Typography variant={'h3'} className={classes.titleHeader}>
                                  About me
                              </Typography>
                          </div>
                          <div className={classes.col}>
                              <div className={classes.profileImage}/>
                          </div>
                      </div>
                      <div className={classes.wholePage}>
                        <div className={classes.sectionContent}>
                              <Typography
                                  className={classes.titleAlignLeft}
                                  variant={'h5'}
                              >
                                  Summary
                              </Typography>
                              <Typography>
                                  My name is Patrick and I am a graduate of the New Jersey Institute of Technology with a Bachelor of Science in Computer Science. My main objective is to progress myself in the tech field as a <b>Senior Software Engineer</b> leveraging my skills in designing application architectures, user experiences, animating, and creating sophisticated backend API's.
                              </Typography>
                              <br/>
                              <Typography>
                                  Aside from being a passionate coder, I enjoy spending some of my time outdoors. In the winter, I am an avid snowboarder and weightlifter. During the warmer months in New Jersey I enjoy running, swimming, weight training, and motocross.
                              </Typography>
                              <br/>
                              <Typography>
                                  When in doors I'm an avid gamer as I have a custom built workstation and a couple consoles (Demon Souls is currently my favorite game).
                                  I also enjoy learning new technologies in my field and am currently teaching myself Vapor (backend framework written in Swift), Swift UI, React, Lottie, and the Adobe Suite creating beautiful experiences and animations.
                              </Typography>
                          </div>
                      </div>
                      <Carousel
                          responsive={responsive}
                          autoPlay={true}
                          autoPlaySpeed={5000}
                          transitionDuration={500}
                          infinite={true}
                          removeArrowOnDeviceType={['tablet', 'mobile']}
                          itemClass={classes.carouselItemContainer}
                      >
                          {
                              images.map((o: any, i: number) => (
                                  <div key={i}>
                                      <img src={o.image} alt={o.alt} className={classes.carouselImage}/>
                                  </div>
                              ))
                          }

                      </Carousel>

                      <Divider className={classes.divider}/>

                      <div className={classes.sectionContent}>
                          <Typography className={classes.titleAlignLeft}
                                      variant={'h5'}
                          >
                              Education
                          </Typography>
                          <div className={classes.educationInsert}>
                              <div className={classes.col}>
                                  <Typography
                                      variant={'subtitle1'}
                                  >
                                      New Jersey Institute of Technology
                                  </Typography>
                                  <Typography
                                      variant={'subtitle2'}
                                  >
                                      BACHELOR OF SCIENCE
                                  </Typography>
                                  <Typography>
                                      Computer Science
                                  </Typography>
                              </div>
                              <div className={classes.col}>
                                  August 2015 - December 2018
                              </div>
                          </div>
                          <div className={classes.educationInsert}>
                              <div className={classes.col}>
                                  <Typography
                                      variant={'subtitle1'}
                                  >
                                      County College of Morris
                                  </Typography>
                                  <Typography
                                      variant={'subtitle2'}
                                  >
                                      ASSOCIATE OF SCIENCE
                                  </Typography>
                                  <Typography>
                                      Computer Science
                                  </Typography>
                              </div>
                              <div className={classes.col}>
                                  August 2011 - August 2015
                              </div>
                          </div>
                      </div>

                      <Divider className={classes.divider}/>

                      <div className={classes.sectionContent}>
                          <Fade right>
                              <Typography className={classes.titleAlignLeft}
                                          variant={'h5'}
                              >
                                  Skills
                              </Typography>
                          </Fade>

                          <Fade right>
                              <Typography variant={'subtitle1'}>
                                  Programming Languages
                              </Typography>
                          </Fade>

                          <div className={classes.row}>
                              <List className={classes.wrapList}>
                                  {
                                      programmingLanguages.map((o: any, i: number) => (
                                          <Fade right key={i}>
                                              <div className={classes.itemWrapper}>
                                                  <ListItem>
                                                      <ListItemIcon>
                                                          <Code/>
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                          {o.lang}
                                                      </ListItemText>
                                                  </ListItem>
                                              </div>
                                          </Fade>
                                      ))
                                  }
                              </List>
                          </div>
                          <Fade right>
                              <Typography variant={'subtitle1'}>
                                  Frameworks
                              </Typography>
                          </Fade>
                          <div className={classes.row}>
                              <List className={classes.wrapList}>
                                  {
                                      frameworks.map((item: any, i: number) => (
                                          <Fade right key={i}>
                                              <div className={classes.itemWrapper}>
                                                  <ListItem>
                                                      <ListItemIcon>
                                                          <Crop/>
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                          {item.framework}
                                                      </ListItemText>
                                                  </ListItem>
                                              </div>
                                          </Fade>
                                      ))
                                  }
                              </List>
                          </div>

                          <Fade right>
                              <Typography variant={'subtitle1'}>
                                  Libraries
                              </Typography>
                          </Fade>
                          <div className={classes.row}>
                              <List className={classes.wrapList}>
                                  {
                                      libraries.map((item: any, i: number) => (
                                          <Fade right key={i}>
                                              <div className={classes.itemWrapper}>
                                                  <ListItem>
                                                      <ListItemIcon>
                                                          <Book/>
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                          {item.library}
                                                      </ListItemText>
                                                  </ListItem>
                                              </div>
                                          </Fade>
                                      ))
                                  }
                              </List>
                          </div>

                          <Fade right>
                              <Typography variant={'subtitle1'}>
                                  Tools
                              </Typography>
                          </Fade>

                          <div className={classes.row}>
                              <List className={classes.wrapList}>
                                  {
                                    tools.map((o: any, i: number) => (
                                        <Fade right key={i}>
                                            <div className={classes.itemWrapper}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Build/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {o.tool}
                                                    </ListItemText>
                                                </ListItem>
                                            </div>
                                        </Fade>
                                    ))
                                  }
                              </List>
                          </div>
                      </div>

                      <Divider className={classes.divider}/>

                      <div className={classes.sectionContent}>
                          <Fade right>
                              <Typography className={classes.titleAlignLeft}
                                          variant={'h5'}
                              >
                                  Experience
                              </Typography>
                          </Fade>

                          <div className={classes.jobInsert}>
                              <div className={classes.col}>
                                  {
                                      experiences.map((exp: any, i: number) => (
                                          <Fade right key={i}>
                                              <div className={classes.flexContainer}>
                                                  <div className={classes.col}>

                                                      <div className={classes.jobRow}>
                                                          <Typography
                                                              variant={'subtitle1'}
                                                          >
                                                              {exp.title}
                                                          </Typography>
                                                      </div>

                                                      <div className={classes.jobRow}>
                                                          <Typography
                                                              variant={'subtitle2'}
                                                          >
                                                              {exp.company}
                                                          </Typography>
                                                      </div>
                                                      <ul>
                                                          {
                                                              exp.points.map((point: any, i2: number) => (
                                                                  <Fade right key={i2}>
                                                                      <li>
                                                                          <Typography>
                                                                              {point.detail}
                                                                          </Typography>
                                                                      </li>
                                                                  </Fade>
                                                              ))
                                                          }
                                                      </ul>
                                                  </div>
                                                  <div className={classes.jobCol}>
                                                      <Typography>
                                                          {exp.duration}
                                                      </Typography>
                                                  </div>
                                              </div>
                                              <div>
                                                  {
                                                      experiences[experiences.length - 1] !== exp ? <Divider/> : null
                                                  }
                                              </div>
                                          </Fade>
                                      ))
                                  }
                              </div>
                          </div>
                      </div>
                  </Paper>
              </Fade>
          </div>
        );
    }
}

export default withStyles(pageAboutStyles, {})(PageAbout);
